@font-face {
  font-family: 'Stratos SemiLight';
  src: url('./Stratos-SemiLight-Web.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Stratos Bold';
  src: url('./Stratos-Bold-Web.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
