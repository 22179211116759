.fontNormal {
  font-family: 'Stratos SemiLight', var(--font-fallback);
}

.fontBold {
  font-family: 'Stratos Bold', var(--font-fallback);
}

.sky {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('./img/sky.jpg');
  background-size: 100% auto;
  background-repeat: repeat;
  opacity: 0.2;
}

.footerImgHolder {
  overflow: hidden;
  max-width: 160rem;
  margin: 0 auto;
}

.footerImgHolder .footerImg {
  margin-left: -60%;
  margin-right: -60%;
  width: 220%;
  max-width: 220%;
}

.asses {
  top: -30%;
  left: -140%;
  max-width: 140%;
}

.rocket {
  margin-left: -20%;
  margin-right: -20%;
  margin-bottom: -5%;
  max-width: 140%;
}

@media screen and (min-width: 40rem) {
  .asses {
    top: -60%;
    left: -100%;
    max-width: 100%;
  }

  .rocket {
    margin: 0;
    max-width: 100%;
  }
}

@media screen and (min-width: 60rem) {
  .footerImgHolder .footerImg {
    margin-left: -40%;
    margin-right: -40%;
    width: 180%;
    max-width: 180%;
  }

  .asses {
    top: -100%;
    left: -100%;
  }
}

@media screen and (min-width: 100rem) {
  .footerImgHolder .footerImg {
    margin-left: -20%;
    margin-right: -20%;
    width: 140%;
    max-width: 140%;
  }
}

@media screen and (min-width: 140rem) {
  .footerImgHolder .footerImg {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    max-width: 100%;
  }
}

.linkable .hoverable {
  @apply text-white;
}

.linkable .hoverable span {
  @apply border-b border-white border-opacity-30 transition-opacity;
}

.linkable:hover .hoverable span {
  @apply border-opacity-100;
}

.largeInput {
  @apply block w-full pt-3 md:pt-7 pb-4 md:pb-8 rounded-xl md:rounded-3xl text-xl md:text-2.5rem text-center leading-135;
}

.purpleButton {
  @apply bg-gradient-to-t from-purple via-purple-light to-purple-lightest shadow-purple opacity-90 text-white transition-opacity;
}

.purpleButton:hover {
  @apply opacity-100;
}
