.button {
  display: block;
  color: #fff !important;
  border: none !important;
  background: #2c159d;
  padding: 8px 28px 11px;
  font-size: 22px;
  line-height: 28px;
  border-radius: 17.5px;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  margin: 0 auto 4px;
  transition: all 0.2s ease-out;
}

.button:hover {
  color: #fff !important;
}

.buttonSmall {
  composes: button;
  display: inline-block;
  font-size: 17px;
  line-height: 22px;
  padding: 8px 28px 10px;
  min-width: 80px;
}

input + .buttonSmall {
  border-radius: 0 17.5px 17.5px 0;
}

.buttonTransparent {
  background: none;
  box-shadow: none;
  border: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.buttonTransparent:hover {
  border: 1px solid rgba(255, 255, 255, 1) !important;
}
