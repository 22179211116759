html {
  font-size: 20px;
}

* {
  outline: none;
}

img {
  max-width: 100%;
}

.linkable {
  color: inherit;
  text-decoration: none;
}

.hoverable {
  transition: 0.1s ease;
}

.linkable:hover .hoverable {
  color: #1a7a77 !important;
}

/* Based on bootstrap breakpoint */
@media screen and (min-width: 576px) {
  h1,
  h2,
  h3,
  .h1,
  .h2,
  .h3 {
    hyphens: manual;
  }
}

body a:hover {
  color: #000;
}
