.field {
  display: flex;
  justify-content: center;
  width: 100%;
}

.field input,
.field a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 1rem;
  outline: none;
  height: 2rem;
  padding: 0;
  line-height: 1;
  text-align: center;
  box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.25);
  transform: translateZ(0);
  transition: 0.1s ease;
  appearance: none;
}

.field a {
  margin-bottom: 0.5em;
  border-radius: 1.25rem;
  height: 2.5rem;
}

.field input[type='submit'] {
  width: 4em;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.field input[type='email'] {
  padding-top: 1px;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.field input[type='email']:focus {
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.2);
}

.field input::placeholder {
  color: rgba(0, 0, 0, 0.2);
}

.field input:disabled {
  opacity: 1;
}

.field + .field {
  margin-top: 0.5rem;
}
