@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-fallback: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

body {
  margin: 0;
  font-family: var(--font-fallback);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
  .link {
    @apply border-b transition-opacity;
  }

  .link:hover {
    @apply border-opacity-100;
  }

  .link-black {
    @apply text-black border-black border-opacity-30;
  }

  .link-black:hover {
    @apply text-black;
  }

  .link-white {
    @apply text-white border-white border-opacity-30;
  }

  .link-white:hover {
    @apply text-white;
  }

  .link-gold {
    @apply text-gold border-gold border-opacity-30;
  }

  .link-gold:hover {
    @apply text-gold;
  }

  .link-cyan {
    @apply text-cyan border-cyan border-opacity-30;
  }

  .link-cyan:hover {
    @apply text-cyan;
  }

  .link-purple {
    @apply text-purple border-purple border-opacity-30;
  }

  .link-purple:hover {
    @apply text-purple;
  }
}

@layer utilities {
  .will-transform {
    will-change: transform;
  }

  @variants responsive {
    .hyphens-auto {
      hyphens: auto;
    }

    .hyphens-manual {
      hyphens: manual;
    }

    .hyphens-none {
      hyphens: none;
    }
  }
}
